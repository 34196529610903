@tailwind base;
@tailwind components;
@tailwind utilities;

.jodit-wysiwyg ul,
.jodit-wysiwyg ol {
  padding-left: 20px;
}

.jodit-wysiwyg ul > li::marker {
  font-size: 1.5em;
}

.jodit-dialog__content {
  padding-left: 10px;
}

/* Hiding id, class, style options in Image Properties */
.jodit-ui-image-position-tab__editStyle,
.jodit-ui-image-position-tab__editClass,
.jodit-ui-image-position-tab__editId {
  display: none !important;
}